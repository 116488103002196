<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 text-left pa-2>
        <span class="itemHeading">SUBTEAM</span>
      </v-flex>

      <v-flex xs12 pa-4>
        <v-card color="#F5F5DC" tile>
          <v-layout wrap pa-2 justify-start v-if="data">
            <v-flex xs12 sm8 md9 align-self-start text-left pa-1>
              <v-layout wrap>
                <v-flex xs12 align-self-center text-left pa-1>
                  <span
                    style="font-size: 18px"
                    class="kumbhMedium"
                    v-if="data.name"
                  >
                    Name : {{ data.name }}
                  </span>
                  <span style="font-size: 18px" class="kumbhMedium" v-else>
                    NA
                  </span>
                </v-flex>
                <v-flex xs9 align-self-center text-left pa-1>
                  <span
                    style="font-size: 18px"
                    class="kumbhMedium"
                    v-if="data.employeeId"
                  >
                    Employee Id : {{ data.employeeId }}
                  </span>
                  <span style="font-size: 18px" class="kumbhMedium" v-else>
                    NA
                  </span>
                </v-flex>
                <v-flex xs9 align-self-center text-left pa-1>
                  <span
                    style="font-size: 18px"
                    class="kumbhMedium"
                    v-if="data.employeeStatus"
                  >
                    Emp. Status : {{ data.employeeStatus }}
                  </span>
                  <span style="font-size: 22px" class="kumbhMedium" v-else>
                    NA
                  </span>
                </v-flex>
                <v-flex xs12 sm6 align-self-center text-left pa-1>
                  <span
                    style="font-size: 18px"
                    class="kumbhMedium"
                    v-if="data.dateofjoining"
                  >
                    Date of joining :
                    {{ data.dateofjoining.slice(0, 10) }}
                  </span>
                  <span style="font-size: 18px" class="kumbhMedium" v-else>
                    Date of joining : NA
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex v-if="list.length > 0" xs12>
        <v-layout wrap>
          <v-flex pa-6 xs4 v-for="(item, i) in list" :key="i">
            <v-card>
              <v-layout wrap>
                <v-flex pa-2 xs12 text-left>
                  <span class="kumbhMedium">Name :</span
                  ><span>{{ item.name }}</span>
                </v-flex>
                <v-flex pl-2 pt-1 xs12 text-left>
                  <span class="kumbhMedium">Email :</span
                  ><span>{{ item.email }}</span>
                </v-flex>

                <v-flex pl-2 pt-1 xs12 text-left>
                  <span class="kumbhMedium">Employee Id :</span
                  ><span>{{ item.employeeId }}</span>
                </v-flex>

                <v-flex pl-2 pt-1 xs12 text-left>
                  <span class="kumbhMedium">Position :</span
                  ><span> {{ item.positionid[0].name }}</span>
                </v-flex>
                <v-flex xs12 pa-2>
              <center>
                <v-btn
                  @click="(removedialogue = true), (curid = item._id)"
                  style="font-family: kumbhBold"
                  dark
                  class="rounded-xl"
                  color="#005f32"
                >
                  REMOVE
                </v-btn>
              </center>
            </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-else text-center>
        <span
          style="
            font-family: opensanssemibold;
            font-size: 25px;
            color: black;
            letter-spacing: 1px;
            cursor: pointer;
          "
          >No Sub Team!</span
        >
      </v-flex>
    </v-layout>
  </div>
</template>
    
    <script>
import axios from "axios";
export default {
  data() {
    return {
      list: [],
      employeeId: this.$route.query.id,
      page: 1,
      curid: [],
      array: [],
      data: [],
      showsnackbar: false,
      msg: null,

      currentpage: 1,
      limit: 12,
      removedialogue: false,
      pages: 0,
      appLoading: false,
    };
  },
  watch: {
    currentpage() {
      this.getlist();
    },
  },
  mounted() {
    this.getlist();
    this.getData();

  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/employee/details/view",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.data;
          this.employeeId = response.data.data._id;
          this.projects = response.data.projects;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    remove() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/coEmployee/remove",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          toEmployeeId: this.$route.query.id,
          employeeId: this.curid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            (this.removedialogue = false), this.getlist();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getlist() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/coEmployee/list",
        params: {
          page: this.currentpage,
          employeeId: this.$route.query.id,
          limit: this.limit,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.array = response.data.data.positionid;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
      